export default {
  namespaced:true,
  state: {
    list: []
  },
  getters: {
    getTextIcons: function (state) {
      return [...state.list];
    }
  },
  mutations: {
    SET_TEXT_ICONS(state, payload){
      state.list = payload;
    }
  },
  actions: {
    setTextIcons(context, payload){
      const textIcons = context.state.list;
      textIcons.forEach(x => textIcons.pop());
      payload.forEach(x => textIcons.push(x));
      context.commit('SET_TEXT_ICONS', textIcons);
    }
  }
}
