import { createStore } from 'vuex';
import icons from './icons.module';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    icons
  }
})
